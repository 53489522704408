import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/template-partials/global-components/section"
import InteriorPageHeader from "../components/template-partials/global-components/interior-page-header"

const WhatIsRecorlev = () => {
  return (
    <Layout>
      <Seo
        title="What is Recorlev? | Recorlev® (levoketoconazole)"
        description="Learn more about Recorlev. Please see Important Safety Information and full Prescribing Information, including Boxed Warning, on this website."
        ogdescription="Learn more about Recorlev. Please see Important Safety Information and full Prescribing Information, including Boxed
      Warning, on this website."
      />
      <InteriorPageHeader alt="What is recorlev">
        <h1>
          What is Recorlev<sup>®</sup>?{" "}
        </h1>
      </InteriorPageHeader>

      <Section className="interior-page">
        <div className="row">
          <div className="columns">
            <h2>What does it do?</h2>
            <p>
              Recorlev<sup>®</sup> (levoketoconazole) is a medicine taken by
              mouth that can help normalize your cortisol levels.<sup>1</sup>{" "}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="columns">
            <h2>How does Recorlev work?</h2>
            <p>
              Recorlev blocks the key steps in the making of cortisol and
              testosterone in the body.<sup>1</sup> This is important because the goal of
              managing Cushing’s is to get your cortisol levels back to normal.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <h2>How to get Recorlev</h2>
            <p>
              Recorlev is only available with a doctor's prescription. PANTHERx
              Rare Pharmacy is the only pharmacy that carries Recorlev and will
              have it delivered right to you. Your support team will help make
              sure you receive your treatment.
            </p>
            <p>
              <strong>
                Talk to your doctor about starting Recorlev if you have symptoms
                of Cushing's or want to try a different medicine for Cushing's.
              </strong>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
          <p className="footnote"><b>References:</b> <b>1.</b> Recorlev [prescribing information]. Chicago, IL: Xeris Pharmaceuticals, Inc.</p> 
          </div>
        </div>

        {/*

        <div className="row py-2 lg_pt-1 lg_pb-3">
          <div className="column text-center">
            <Button
              ButtonLink={'/taking-recorlev/'}
              ButtonClass={'primary gtm-cta'}
              ButtonText={'Learn how to monitor your progress'}
              ButtonIconAfter={'&nbsp;&gt;'}
              EventLabel={'Learn how to monitor your progress'}
            >
            </Button>
          </div>
        </div>
        */}
      </Section>
    </Layout>
  )
}

export default WhatIsRecorlev
